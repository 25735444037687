import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Index from '@/views/index.vue'
import noteindex from '@/views/notes_index.vue'
import shatePage from '@/views/share_notes.vue'
import login from '@/views/pages/login.vue'
import Cookies from "js-cookie";

import SubscriptionComponent from '@/views/pages/index_page/SubscriptionComponent.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'Homeindex',
        component: Home,
        meta: {
            title: "EasyNoteAI Notes",
            requiresAuth: true,
        },
    },
    {
        path: '/home/:pagekey',
        name: 'Homeindex_key',
        component: Home,
        meta: {
            title: "EasyNoteAI Notes",
            requiresAuth: true,
        },
    },
    {
        path: '/',
        name: 'IndexPage',
        component: Index,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/page/:pagekey',
        name: 'IndexPage_key',
        component: Index,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/notes',
        name: 'notesPage',
        component: noteindex,
        props: true,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/notes/:pagekey',
        name: 'notesPage_key',
        component: noteindex,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/share',
        name: 'shatePage',
        component: shatePage,
        meta: {
            title: "EasyNoteAI Notes",
        },
    },
    {
        path: '/login',
        name: 'loginPage',
        component: login,
        meta: {
            title: "EasyNoteAI",
        },
    },
    {
        path: '/SubscriptionComponent',
        name: 'SubscriptionComponent',
        component: SubscriptionComponent,
        meta: {
            title: "EasyNoteAI",
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    console.log(from, 'from');
    const subid = Cookies.get('subid');
    // console.log(requiresAuth, 'requiresAuth',subid);
    if (requiresAuth && !subid) {
        // 如果需要登录且用户未登录，则重定向到登录页面
        next({ name: 'loginPage' });
    } else {
        next();
    }
});

export default router
