<template>
    <div class="realtimeaudio">
        <div class="backhome" @click="backhomepage"></div>
        <div class="gosupports" v-if="outlanjie" @click="clickleft_tipsave('supports')"></div>
        <div class="gosetting" v-if="outlanjie" @click="clickleft_tipsave('setting')"></div>
        <div class="gouserinfo" v-if="outlanjie" @click="clickleft_tipsave('userinfo')"></div>
        <div class="recode_notesbox">
            <div class="recordlecture" ref="recordlecture">
                <div class="toptips">
                    <h1>
                        <img :src="record_icon" alt="">
                        <span>Record Live Lecture</span>
                    </h1>
                </div>
                <div class="notradio" v-show="radiostatus == 'notradio'">
                    <div @click="connectWebSocket" class="yuan_btn record-button1">
                        <i class="el-icon-microphone"></i>
                    </div>
                    <div class="recordin">
                        <div class="select-group">
                            <label class="right-label"><img :src="record_icon_l" alt="">Record in：</label>
                            <el-select :disabled="elapsedTime > 0" v-model="appkey" class="selecttool"
                                placeholder="Select">
                                <el-option v-for="item in appkeyarr" :key="item.value" :label="item.name" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="select-group">
                            <label><img :src="record_icon_r" alt="">Note Language：</label>
                            <el-select v-model="languagesItem" class="selecttool" placeholder="Select">
                                <el-option v-for="item in languages" :key="item.code" :label="item.name" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- <el-select style="width: 200px; margin-top: 10px" v-model="zhandianurl" clearable placeholder="选择站点">
                    <el-option v-for="item in zhandianobj" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> -->
                    <!-- <el-input style="width: 200px; margin-top: 10px" placeholder="Token" v-model="realtimetoken"
                    clearable></el-input> -->

                </div>
                <div class="startradio" v-show="radiostatus == 'startradio'">
                    <div class="waveform">
                        <canvas ref="waveformCanvas" width="300" height="50"></canvas>
                    </div>

                    <div class="controls">
                        <div class="timer">{{ formattedTime }}</div>
                        <div @click="toggleRecording()" class="yuan_btn record-button">
                            <i v-if="isRecording" class="el-icon-video-pause"></i>
                            <i v-else-if="isReConnection" class="el-icon-loading"> </i>
                            <i v-else class="el-icon-microphone record-button-microphone"></i>

                        </div>
                        <div @click="stopRecording" class="yuan_btn stop-button" :disabled="!audioUrl">
                            <i></i>
                        </div>
                        <div v-if="false" @click="clearRecording" class="clear-button" :disabled="!audioUrl">
                            restart
                        </div>
                    </div>
                </div>
                <div class="stopradio" v-show="radiostatus == 'stopradio'">
                    <div class="waveform">
                        <canvas ref="waveformCanvas_R" width="300" height="50"></canvas>
                        <audio ref="audioPlayer" :src="audioUrl" @timeupdate="onTimeUpdate"
                            @ended="onAudioEnded"></audio>
                    </div>

                    <div class="controls">
                        <div class="timer">
                            <span>{{ formatTime(currentTime) }} / {{ formatTime(elapsedTime) }}</span>
                        </div>
                        <div @click="togglePlay()" class="record-button">
                            <i v-if="!isPlaying" class="el-icon-video-play"></i>
                            <i v-else class="el-icon-video-pause"></i>
                        </div>
                        <div v-if="false" @click="clearRecording" class="clear-button" :disabled="!audioUrl">
                            restart
                        </div>
                        <el-button @click="downloadAudio" class="clear-button download-button"
                            :disabled="!audioUrl || uploadstatus == 'uploading'">
                            Save audio locally
                        </el-button>

                    </div>
                    <div class="overaudiobox" v-if="false">

                        <el-button v-loading="isloading" element-loading-spinner="el-icon-loading"
                            :element-loading-text="loadingText" element-loading-background="rgba(21, 23, 32, 0.8)"
                            @click="openrecordtool" class="generate-notes-button" :disabled="!audioUrl">
                            Generate Notes
                        </el-button>
                        <span :class="{ tipred: true, shake: tipredshow }" v-if="tipredshow">The recording is less than
                            1
                            minute</span>
                        <span :class="{ tipred: true, shake: uploadstatus == 'error' }"
                            v-if="uploadstatus == 'error'"><i class="el-icon-warning"></i>Upload fail. Save audio
                            locally. <br> Retry later
                            with a better network connection. </span>
                        <el-button @click="downloadAudio" class="clear-button download-button"
                            :disabled="!audioUrl || uploadstatus == 'uploading'">
                            Save audio locally
                        </el-button>

                    </div>

                </div>
                <!-- <div class="tipbox">
                Tips:<br>Do not close the tab while your recording is in progress. <br>
                For best results, please <span>record</span> in a quiet area and position your
                device close to the sound.
            </div> -->
                <div class="alltextbox" ref="alltextbox">
                    <div class="textbox" :ref="textobj.text_id" v-for="(textobj, i) in textobjs" :key="i">
                        <h5>{{ textobj.time }}</h5>
                        <div class="yuantext">{{ textobj.text }}</div>
                        <div class="translatetext"> {{ textobj.trans }}</div>
                    </div>
                    <div v-if="streamobj.stream_text" class="textbox">
                        <h5>{{ streamobj.stream_time }}</h5>
                        <div class="yuantext">{{ streamobj.stream_text }}</div>
                        <div class="translatetext"> {{ streamobj.stream_trans }}</div>
                    </div>
                </div>
            </div>
            <div class="resizer" ref="resizer"></div>
            <div class="recordtotext" ref="recordtotext">
                <div class="titlebox">
                    <div class="websocketbtnbox" v-if="false">
                        <el-select v-model="zhandianurl" clearable placeholder="选择站点">
                            <el-option v-for="item in zhandianobj" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-model="appkey" clearable placeholder="选择模型语言">
                            <el-option v-for="item in appkeyarr" :key="item.value" :label="item.zhName"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input style="width: 200px;" placeholder="断句间隔(200～2000)" v-model="maxSentenceSilence"
                            clearable>
                        </el-input>
                        <el-button class="openwebsocket" :disabled="isConnected" @click="connectWebSocket">
                            {{ isConnected ? '识别中...' : '开启识别' }}
                        </el-button>
                        <el-checkbox v-model="isyuyiduanju">语义断句</el-checkbox>
                        <el-input placeholder="Token" v-model="realtimetoken" clearable></el-input>
                    </div>
                    <!-- <div class="t_right">
                    Translate in :
                </div> -->

                    <h1>
                        <span> Live Notes:</span>
                    </h1>
                </div>
                <div class="allnotesbox" ref="allnotesbox">
                    <div class="notesbox" v-for="(partobj, i) in partobjs" :key="i">
                        <h5>{{ partobj.time }}</h5>
                        <!-- <div class="yuantext">{{ partobj.text }}</div> -->
                        <div class="yuantext markdown-content" v-if="partobj.notes" v-html="partobj.notes"></div>
                        <div class="yuantext markdown-content" v-else>
                            <div class="loading_img_box">
                                <img :src="loading_img" alt="">
                                <span>We are generating notes...</span>
                            </div>
                        </div>

                    </div>
                    <div class="tipstextbox" v-if="partobjs.length == 0">
                        <p>Just a sec... Your notes are almost ready. 😊😊😊</p>
                        <p>Make sure the recording is longer than 1 minute.</p>
                    </div>
                </div>
            </div>
        </div>

        <popuprecordsave ref="pRecordsave" :percentage="uploadProgress" @saveaudio="saveaudio" />
        <!-- <popupchaoxian ref="fristSave" :textcontent="fristSavetext" @saveaudio="downloadAudio" /> -->
        <popupchaoxian ref="leaveSave" :textcontent="leaveSavetext" :tonextname="leave_tonext" @saveaudio="saveaudio"
            @cancel="cancelSave" />
        <PopupSaveFailed ref="saveFailedPopup" @retry="handleRetryUpload" @close="handleCloseFailedPopup" />
    </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import IndexedDBAudioStorage from "@/assets/js/indexedDB.js";
import popuprecordsave from "@/views/pages/home_page/popups/popup_record_save.vue";
import popupchaoxian from "@/views/pages/home_page/popups/popup_record_leave.vue";
import PopupSaveFailed from '@/views/pages/home_page/popups/popup_save_failed.vue'

export default {
    components: {
        popuprecordsave,
        popupchaoxian,
        PopupSaveFailed,
    },
    props: {
        uploadstatus: {
            type: String,
            default: '',
        },
        recordtitle: {
            type: String,
            default: "",
        },
    },
    data() {
        return {

            record_icon_l: require("@/assets/images/home/icon_live_l.png"),
            record_icon_r: require("@/assets/images/home/icon_live_r.png"),
            loading_img: require("@/assets/images/noteindex/loading_img1.gif"),
            record_icon: require("@/assets/images/home/record_icon.png"),
            // notradio startradio stopradio
            radiostatus: "notradio",

            isRecording: false,
            isPaused: false,
            stream: null,
            mediaRecorder: null,
            audioUrl: null,
            startTime: 0,
            elapsedTime: 0,
            pauseTime: 0,
            timer: null,
            audioContext: null,
            analyser: null,
            dataArray: null,

            canvasContext: null,
            animationFrame: null,
            isPlaying: false,
            currentTime: 0,
            playbackAnalyser: null,
            playbackDataArray: null,
            playbackAnimationFrame: null,
            playaudioContext: null,
            audioSource: null,
            isloading: false,
            tipredshow: false,

            textobjs: [
                // {
                //     text: "asdasdasdasdasda asdasda  asdasdasds",
                //     time: '00:11',
                //     trans: 'asdasdasdasdasda asdasda  asdasdasds'
                // },
                // {
                //     text: "哈哈哈；较发达；地方啊快点减肥；阿煎豆腐； 阿接受的；阿列克煎豆腐 asdasda  asdasdasds",
                //     time: '00:16',
                //     trans: 'asdasdasdasdasda asdasda  asdasdasds'
                // }
            ],
            mysocket: null,
            streamobj: { stream_time: "", stream_text: "", stream_trans: "" },

            partobjs: [],
            partobj: {
                text: "",
                time: "",
                trans: "",
                notes: "",
                text_ids: [],
                partobj_id: "",
                timelength: 0,
            },
            getparttimer: null,
            noteid: -1,
            md: new MarkdownIt({
                html: true,
                linkify: true,
                typographer: true,
                highlight: function (str, lang) {
                    if (lang && hljs.getLanguage(lang)) {
                        try {
                            return hljs.highlight(lang, str).value;
                        } catch (__) {
                            console.log("error", str, lang);
                        }
                    }
                    return ""; // 使用默认的转义
                },
            }),

            message_id: "05450bf69c53413f8d88aed1ee60abcd",
            task_id: "640bc797bb684bd6960185651307qwer",
            scriptProcessor: null,


            languages: [
                { code: "EN", name: "English", value: "1", },
                { code: "ZH", name: "简体中文", value: "2" },
                { code: "ZH(T)", name: "繁体中文", value: "3" },
                { code: "ES", name: "Spanish", value: "4" },
                { code: "FR", name: "French", value: "5" },
                { code: "DE", name: "German", value: "6" },
                { code: "IT", name: "Italian", value: "7" },
                { code: "JA", name: "Japanese", value: "8" },
                { code: "RU", name: "Russian", value: "9" },
                { code: "AR", name: "Arabic", value: "10" },
                { code: "HI", name: "Hindi", value: "11" },
                { code: "PT", name: "Portuguese", value: "12" },
                { code: "NL", name: "Dutch", value: "13" },
                { code: "KO", name: "Korean", value: "14" },
                { code: "TR", name: "Turkish", value: "15" }
            ],
            languagesItem: {},

            appkeyarr: [
                { code: "en", name: "English", zhName: "英语", value: "3G3o7AlY1ncgnW8b" },
                { code: "zh", name: "中文", zhName: "中文", value: "zEURyO5izWCp17u9" },
                { code: "yue", name: "粤语", zhName: "粤语", value: "kV0JzwGpXHxGjMk6" },
                { code: "ru", name: "Russian", zhName: "俄语", value: "MdjmRjtynQQzfLmr" },
                { code: "ja", name: "Japanese", zhName: "日语", value: "03OLyZgpZdSbw2o2" },
                { code: "ko", name: "Korean", zhName: "韩语", value: "Qr672JcoALV09g5H" },
                { code: "de", name: "German", zhName: "德语", value: "XGBANgEKxxTEGSkR" },
                { code: "es", name: "Spanish", zhName: "西班牙语", value: "mf07QEy4zMvKNiCA" },
                { code: "fr", name: "French", zhName: "法语", value: "5WE4hnc6dzWI5o2C" },
                { code: "it", name: "Italian", zhName: "意大利语", value: "zhbOHHj9I3ejSOWk" },
                { code: "ar", name: "Arabic", zhName: "阿拉伯语", value: "40KHVNDUGFQw47E1" },
                { code: "hi", name: "Hindi", zhName: "印度", value: "c7Gb2aadvsMjhqkn" },
                // { code: "pt", name: "Portuguese", zhName: "葡萄牙语", value: "Q1OQu8IdrxVTleWI" },
                { code: "nl", name: "Dutch", zhName: "荷兰语", value: "NF9tLDmn7t9Yz1Ro" },
                // { code: "tr", name: "Turkish", zhName: "土耳其语", value: "mVMK8FtiDkohE4xw" },
                // { code: "kk", name: "Kazakh", zhName: "哈萨克语", value: "E8VE0jfSnJiliBqz" },
                // { code: "vi", name: "Vietnamese", zhName: "越南语", value: "pNAnqLsils8rm4CB" },
                // { code: "tl", name: "Filipino", zhName: "菲律宾语", value: "SOAnloTzZDpbtroU" },
                // { code: "ms", name: "Malay", zhName: "马来语", value: "npUaYoCa6S1yetiB" }
            ],
            appkey: {},
            maxSentenceSilence: '2000',
            isyuyiduanju: false,
            zhandianobj: [{
                value: 'nls-gateway-singapore.aliyuncs.com',
                label: '新加坡'
            }, {
                value: 'nls-gateway.cn-shanghai.aliyuncs.com',
                label: '上海'
            }],
            zhandianurl: 'nls-gateway-singapore.aliyuncs.com',
            realtimetoken: '',


            websocket: null,
            taskId: null,
            messageId: null,
            isConnected: false,
            chunks: [],
            chunkInterval: 60000,
            chunkCounter: 0,
            maxrecordtime: 60,
            liveMinutes: 0,
            keepAliveInterval: null,
            lastActiveTime: null,
            pcmData: [],

            chooeseLanguage: "",
            chooeseLanguageName: "",
            recordtool: 'title',

            fristSavetext: "The recording will only be saved for <span class='redstrong'>24 hours</span>.<br> If the recording is important to you, please <span class='redstrong'>save the audio locally</span> before generating notes.",
            leaveSavetext: "Leaving this page will lose the unsaved audio. <br>Do you want to save the audio file locally?",
            leave_tonext: "",

            mtype: 0,

            outlanjie: false,
            uploadProgress: 0,

            retryCount: 0,

            recordingState: 0, // 0: 初始状态, 1: 录音中, 2: 录音已保存

            noteTaskQueue: [], // 笔记生成任务队列
            failedTasks: [], // 失败任务队列
            queueTimer: null, // 队列处理定时器
            retryTimer: null, // 失败任务重试定时器
            isProcessingQueue: false, // 是否正在处理队列

            isOnline: navigator.onLine, // 添加网络状态标志

            isReConnection: false,  //

            reconnectTimer: null, // 添加重连定时器
            checkConnectionTimer: null, // 添加连接检查定时器

        };
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.elapsedTime / 60);
            const seconds = this.elapsedTime % 60;
            return `${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`;
        },
        loadingText() {
            return `Uploading... ${this.uploadProgress}%`;
        },
        islangtime() {
            // if (this.elapsedTime > this.liveMinutes) {
            //     return true;
            // }
            return this.elapsedTime > 60 * this.maxrecordtime;
        },
    },
    mounted() {

        this.canvasContext = this.$refs.waveformCanvas.getContext("2d");
        this.mtype = this.$cookies.get("m_tkn", 0);
        if (this.mtype == 0) {
            this.maxrecordtime = 15;
        } else {
            this.maxrecordtime = 180;
        }
        // this.fetchLiveMinutes();
        this.fetchKey();
        this.initTaskTimers();
        this.$nextTick(() => {
            this.initResizer();
        });

        // 添加网络状态监听
        window.addEventListener('online', this.handleNetworkChange);
        window.addEventListener('offline', this.handleNetworkChange);
    },
    watch: {
        textobjs: {
            handler() {
                this.scrollToBottom();
            },
            deep: true
        },
        streamobj: {
            handler() {
                this.scrollToBottom();
            },
            deep: true
        }
    },
    methods: {
        async uploadToR2(file) {
            try {
                // 1. 获取预签名URL
                const { data } = await this.$axios.post('/api/get_upload_url/', {
                    fileName: file.name,
                    fileType: file.type
                });

                if (!data.uploadUrl) {
                    throw new Error('Failed to get upload URL');
                }

                // 2. 使用 XMLHttpRequest 上传文件以获取进度
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();

                    // 监听上传进度
                    xhr.upload.onprogress = (event) => {
                        if (event.lengthComputable) {
                            const percentComplete = Math.max(2, Math.floor((event.loaded / event.total) * 100));
                            console.log(`Upload progress: ${percentComplete}%`);

                            // 更新上传进度
                            if (percentComplete < 99) {
                                this.uploadProgress = percentComplete;
                            }
                        }
                    };

                    // 上传完成
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            resolve({
                                success: true,
                                fileKey: data.fileKey,
                                url: data.uploadUrl.split('?')[0]
                            });
                        } else {
                            reject(new Error(`Upload failed with status: ${xhr.status}`));
                        }
                    };

                    // 上传错误
                    xhr.onerror = () => {
                        reject(new Error('Network error during upload'));
                    };

                    // 开始上传
                    xhr.open('PUT', data.uploadUrl, true);
                    xhr.setRequestHeader('Content-Type', file.type);
                    xhr.send(file);
                });

            } catch (error) {
                console.error('Upload failed:', error);
                this.$message.error(`Upload failed: ${error.message}`);
                throw error;
            }
        },
        qiehuanzhan() {
            // this.zhandianurl = this.zhandianobj[1].value;
            this.$refs.pRecordsave.openModal();
        },
        async fetchLiveMinutes() {
            try {
                const subid = this.$cookies.get("subid", "");
                const response = await this.$axios.post('/api/getliveminutes/', {
                    subid: subid,
                });
                const resdata = response.data;
                if (resdata.code === 200) {
                    // this.configuredMinutes = response.data.configured_minutes;
                    // this.uploadedMinutes = response.data.uploaded_minutes;
                    // console.log(resdata);
                    this.liveMinutes = resdata.configured_minutes - resdata.uploaded_minutes;
                }
            } catch (error) {
                console.error('Error fetching live minutes:', error);
                return;
            }
        },
        leaveSave(tonext) {
            if (this.elapsedTime > 10) {
                this.leave_tonext = tonext;
                this.$refs.leaveSave.openModal();
                return '';
            } else {
                return tonext;
            }
            // this.$parent.showhome = "radio";
        },
        async fetchKey() {
            try {
                const response = await this.$axios.get('https://www.easynoteai.com/api/get_key');
                const data = response.data;
                this.realtimetoken = data.token;
            } catch (error) {
                console.error('Error fetching key:', error);
            }
        },

        backhomepage() {
            if (this.outlanjie) {
                this.$refs.leaveSave.openModal();
            } else {
                this.$emit("backhomepage");
            }

        },
        clickleft_tipsave(key) {
            console.log("clickleft_tipsave", key);
            if (this.recordingState === 1) { // 只在录音中状态触发弹窗
                this.$refs.leaveSave.openModal();
            }
        },
        togglePlay() {
            if (this.isPlaying) {
                this.$refs.audioPlayer.pause();
                cancelAnimationFrame(this.playbackAnimationFrame);
            } else {
                this.$refs.audioPlayer
                    .play()
                    .then(() => {
                        this.setupPlaybackAnalyser();
                        this.drawPlaybackWaveform();
                    })
                    .catch((error) => {
                        console.error("Error playing audio:", error);
                    });
            }
            this.isPlaying = !this.isPlaying;
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            const seconds = Math.floor(time % 60);
            return `${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`;
        },
        formatDate_ymd(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        onAudioEnded() {
            this.isPlaying = false;
            cancelAnimationFrame(this.playbackAnimationFrame);
        },
        onTimeUpdate(e) {
            this.currentTime = e.target.currentTime;
        },
        async toggleRecording() {
            if (this.isReConnection) {
                return;
            }
            if (!this.isRecording && !this.isPaused) {
                await this.startRecording();
            } else if (this.isRecording && !this.isPaused) {
                this.pauseRecording();
            } else if (this.isPaused) {
                this.resumeRecording();
            }
        },
        async saveChunk(chunk) {
            try {
                await IndexedDBAudioStorage.saveChunk(chunk);
            } catch (error) {
                console.error("Error saving chunk:", error);
            }
        },
        async startRecording() {
            if (this.radiostatus != "startradio") {
                this.radiostatus = "startradio";
                this.outlanjie = true;
                this.recordingState = 1; // 设置为录音中状态
            }
            try {
                this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.audioContext = new (window.AudioContext ||
                    window.webkitAudioContext)({ sampleRate: 16000 });
                const source = this.audioContext.createMediaStreamSource(this.stream);
                this.analyser = this.audioContext.createAnalyser();
                this.analyser.fftSize = 2048;
                source.connect(this.analyser);
                this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
                this.mediaRecorder = new MediaRecorder(this.stream);
                this.mediaRecorder.addEventListener("dataavailable", async (event) => {
                    if (event.data.size > 0) {
                        // this.chunks.push(event.data);
                        await this.saveChunk(event.data);
                        this.chunkCounter++;
                    }
                    if (this.islangtime) {
                        this.openvipchaoxian();
                        this.stopRecording();
                    }
                });
                const scriptProcessor = this.audioContext.createScriptProcessor(2048, 1, 1)
                scriptProcessor.onaudioprocess = async (event) => {
                    if (!this.isRecording) {
                        return;
                    }
                    const inputData = event.inputBuffer.getChannelData(0);
                    const inputData16 = new Int16Array(inputData.length);
                    for (let i = 0; i < inputData.length; ++i) {
                        inputData16[i] = Math.max(-1, Math.min(1, inputData[i])) * 0x7FFF;
                    }
                    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                        this.lastActiveTime = Date.now();
                        this.websocket.send(inputData16.buffer);
                    } else {
                        this.pauseRecording();
                        console.log('websocket not open');
                        // await this.reconnectWebSocket();
                    }

                    // this.pcmData.push(...inputData16);
                };
                source.connect(scriptProcessor);
                scriptProcessor.connect(this.audioContext.destination);
                this.mediaRecorder.addEventListener("stop", async () => {

                    if (this.isRecording && this.mediaRecorder?.state === 'inactive') {
                        this.mediaRecorder.start();
                    } else {
                        await this.finalizeRecording();
                    }
                });
                this.mediaRecorder.start(1000);
                this.isRecording = true;
                this.isPaused = false;
                if (this.pauseTime != 0) {
                    this.startTime += Date.now() - this.pauseTime;
                    this.pauseTime = 0;
                } else {
                    this.startTime = Date.now();
                }
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(() => {
                    this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
                    if (this.islangtime) {
                        this.openvipchaoxian();
                        this.stopRecording();
                    }
                }, 1000);

                this.drawWaveform();


            } catch (err) {
                console.error("Error accessing microphone:", err);
            }
        },
        async pauseRecording() {
            if (this.isRecording && !this.isPaused && this.mediaRecorder.state === "recording") {
                this.mediaRecorder.pause();
                this.isPaused = true;
                this.isRecording = false;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.pauseTime = Date.now();
            }
        },
        async resumeRecording() {
            console.log('WebSocket状态:', {
                exists: !!this.websocket,
                readyState: this.websocket?.readyState,
                isConnected: this.isConnected
            });

            // 检查WebSocket连接状态
            if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN ) {

                const connected = await this.reconnectWebSocket();
                if (!connected) {
                    //英文
                    // this.$message.error('Failed to establish WebSocket connection, please check your network and try again.');
                    return;
                }
            }

            // WebSocket连接成功，继续录音
            if (this.isPaused && this.mediaRecorder.state === "paused") {
                this.mediaRecorder.resume();
                this.isPaused = false;
                this.isRecording = true;
                this.startTime += Date.now() - this.pauseTime;

                if (this.timer) {
                    clearInterval(this.timer);
                }

                this.timer = setInterval(() => {
                    this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
                    if (this.islangtime) {
                        this.openvipchaoxian();
                        this.stopRecording();
                    }
                }, 1000);

                this.audioContext
                    .resume()
                    .then(() => {
                        this.drawWaveform();
                    })
                    .catch((error) =>
                        console.error("Error resuming audio context:", error)
                    );
            }
        },

        generateUUID() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            ).replace(/-/g, '');
        },
        generateUUID8() {
            // 返回当前时间戳的整数部分，并截取或补零使其长度为八位
            const timestamp = Math.floor(Date.now() / 1000).toString();
            return timestamp;
        },
        connectWebSocket() {
            if (!this.zhandianurl) {
                this.$message.error('请选择站点'); return;
            }
            if (!this.appkey || !this.appkey.value) {
                this.$message.error('Please select the Record language first.'); return;
            }
            if (!this.languagesItem || !this.languagesItem.name) {
                this.$message.error('Please select the Note language first.'); return;
            }
            this.chooeselanguage(this.appkey.value, this.languagesItem.name);
            const mss_num = this.maxSentenceSilence * 1;
            if (!mss_num || mss_num < 200 || mss_num > 2000) {
                this.$message.error('请输入合法断句的时长'); return;
            }

            // console.log('查看参数', this.zhandianurl, this.appkey, this.maxSentenceSilence, mss_num, this.isyuyiduanju, this.realtimetoken);
            const socketUrl = `wss://${this.zhandianurl}/ws/v1?token=${this.realtimetoken}`;
            this.taskId = this.generateUUID();
            this.messageId = this.generateUUID();
            this.websocket = new WebSocket(socketUrl);
            this.websocket.onopen = async () => {
                console.log('连接到 WebSocket 服务器', socketUrl);
                if (this.mediaRecorder == null) {
                    await IndexedDBAudioStorage.clearDB();
                    this.startRecording();
                }

                const startTranscriptionMessage = {
                    header: {
                        appkey: this.appkey.value,
                        namespace: "SpeechTranscriber",
                        name: "StartTranscription",
                        task_id: this.taskId,
                        message_id: this.messageId
                    },
                    payload: {
                        format: "pcm",
                        sample_rate: 16000,
                        enable_intermediate_result: true,
                        enable_punctuation_prediction: true,
                        enable_inverse_text_normalization: true,
                        max_sentence_silence: mss_num,
                        enable_semantic_sentence_detection: this.isyuyiduanju
                    }
                };
                if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                    // console.log('发送消息: ' + JSON.stringify(startTranscriptionMessage));
                    this.websocket.send(JSON.stringify(startTranscriptionMessage));
                }
                this.lastActiveTime = Date.now();
                this.keepAliveInterval = setInterval(() => {
                    if (Date.now() - this.lastActiveTime > 60000) {
                        this.disconnectWebSocket();
                    } else {
                        this.sendSilence();
                    }
                }, 10000);
            };
            this.websocket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                if (message.header.name === "TranscriptionStarted") {
                    this.isConnected = true;
                } else if (message.header.name === 'TranscriptionResultChanged') {
                    if (this.isRecording) {
                        this.streamobj.stream_text = message.payload.result;
                        if (!this.streamobj.stream_time) {
                            this.streamobj.stream_time = this.formattedTime;
                        }
                        this.streamobj.stream_trans = '';
                    }

                } else if (message.header.name === 'SentenceEnd') {
                    // console.log('一句话结束:', message.payload);
                    // 随机id
                    const textid = this.generateUUID8();
                    const pushmsg = {
                        time: this.streamobj.stream_time,
                        text: message.payload.result,
                        trans: this.streamobj.stream_trans,
                        text_id: textid,
                    };
                    this.textobjs.push(pushmsg);
                    this.streamobj = { stream_time: "", stream_text: "", stream_trans: "" }

                    // this.partobj={
                    //     text: "",
                    //     time: "",
                    //     trans: "",
                    //     notes: "",
                    //     text_ids:[],
                    //     partobj_id: "",
                    //     timelength: 0,
                    // },
                    // 获取partobjs最后一个对象

                    this.partobj.text = this.partobj.text + message.payload.result;
                    this.partobj.text_ids.push(textid);
                    if (this.partobj.time == '') {
                        this.partobj.time = pushmsg.time;

                    }
                    if (this.partobj.partobj_id == '') {
                        this.partobj.partobj_id = this.generateUUID8();
                    }

                    if (this.partobj.text.length > 600 || this.elapsedTime - this.partobj.timelength > 70) {
                        const currentIndex = this.partobjs.length;
                        this.partobjs.push(this.partobj);
                        this.addNoteTask(this.partobj, currentIndex);

                        this.partobj = {
                            text: "",
                            time: "",
                            trans: "",
                            notes: "",
                            text_ids: [],
                            partobj_id: "",
                            timelength: this.elapsedTime,
                        };
                    }
                }
            };
            this.websocket.onerror = (event) => {
                // 打印错误信息
                console.log('WebSocket 错误: ' , event);
                this.fetchKey();
                // this.$message.error('The network connection is unstable, please try again.');
            };
            this.websocket.onclose = () => {
                console.log('与 WebSocket 服务器断开');
                this.isConnected = false;
            };
        },
        async generatenote_part(part_obj) {
            const subid = this.$cookies.get("subid", "");

            let notelanguage = this.languagesItem.name;
            if (this.languagesItem?.code == 'ZH') {
                notelanguage = 'Chinese';
            }
            if (this.languagesItem?.code == 'ZH(T)') {
                notelanguage = 'Traditional Chinese (Hong Kong)';
            }

            const res = await this.$axios.post('/api/create_partnote/', {
                part_obj: JSON.stringify(part_obj),
                text_objs: JSON.stringify(this.textobjs),
                noteid: this.noteid,
                subid: subid,
                elapsedTime: this.elapsedTime,
                note_name: this.recordtitle,
                languagein: this.appkey.code,
                language: notelanguage,
            });
            const resdata = res.data;
            if (resdata.code == 200) {
                this.noteid = resdata.noteid;
                const askid = resdata.askid;
                this.getpartnote(askid)
            }
        },
        getpartnote(id) {
            // 先清理已存在的定时器
            if (this.getparttimer) {
                clearInterval(this.getparttimer);
            }

            this.getparttimer = setInterval(() => {
                this.$axios.post('/api/get_partnote/', { askid: id })
                    .then((res) => {
                        const resdata = res.data;
                        if (resdata.code == 200) {
                            // ... 处理成功响应 ...
                            const answer = resdata.answer;
                            const partinfo = resdata.noteinfo;
                            // console.log(partinfo, answer);
                            this.partobjs.forEach((item, index) => {
                                if (item.partobj_id == partinfo.partobj_id) {
                                    this.partobjs[index] = { ...item, notes: this.md.render(answer) };
                                }
                            });
                            this.retryCount = 0;
                            clearInterval(this.getparttimer);
                        } else if (resdata.code == 201) {
                            clearInterval(this.getparttimer);
                            this.retryCount = 0;
                        } else {
                            // 添加最大重试次数限制
                            this.retryCount = (this.retryCount || 0) + 1;
                            if (this.retryCount >= 20) { // 比如最多重试10次
                                clearInterval(this.getparttimer);
                                this.retryCount = 0; // 重置重试次数
                                this.$message.error('Failed to get note after multiple retries');
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching note:', error);
                        clearInterval(this.getparttimer);
                        this.retryCount = 0;
                        this.$message.error('Failed to get note');
                    });
            }, 1000);
        },
        sendSilence() {
            const silenceData = new Int16Array(2048);
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                this.websocket.send(silenceData.buffer);
            }
        },
        async clearRecording() {
            this.$cookies.set('ischilddeep', '')
            this.audioUrl = null;

            this.tipredshow = false;
            this.pauseTime = 0;
            this.isPlaying = false;
            this.isPaused = false;
            this.currentTime = 0;
            this.$parent.uploadstatus = ''

            if (this.timer) {
                clearInterval(this.timer);
            }
            this.elapsedTime = 0;
            this.canvasContext.clearRect(
                0,
                0,
                this.$refs.waveformCanvas.width,
                this.$refs.waveformCanvas.height
            );
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
                this.mediaRecorder.onstop = () => {
                    // 关闭麦克风
                    if (this.stream) {
                        this.stream.getTracks().forEach((track) => track.stop());
                    }
                    this.isRecording = false;
                };
            }
            this.mediaRecorder = null;
            this.stream = null;
            await IndexedDBAudioStorage.clearDB();
            if (this.audioContext && this.audioContext.state !== "closed") {
                this.audioContext
                    .close()
                    .catch((error) =>
                        console.error("Error closing audio context:", error)
                    );
            }
            if (this.analyser) {
                this.analyser.disconnect();
                this.analyser = null;
            }

            this.streamobj = { stream_time: "", stream_text: "", stream_trans: "" }
            this.textobjs = [];
            this.partobjs = [];
            this.noteid = -1;

            this.cleanupAudioContext();

            this.radiostatus = "notradio";
            this.recordingState = 0; // 重置状态
        },
        cleanupAudioContext() {
            this.$refs?.audioPlayer?.pause();
            if (this.audioSource) {
                this.audioSource.disconnect();
                this.audioSource = null;
            }
            if (this.playbackAnalyser) {
                this.playbackAnalyser.disconnect();
                this.playbackAnalyser = null;
            }
            if (this.playaudioContext && this.playaudioContext.state !== "closed") {
                this.playaudioContext
                    .close()
                    .catch((error) =>
                        console.error("Error closing audio context:", error)
                    );
            }
            this.playaudioContext = null;
            this.playbackDataArray = null;
            cancelAnimationFrame(this.animationFrame);
            cancelAnimationFrame(this.playbackAnimationFrame);
        },
        async finalizeRecording() {
            try {
                const chunks = await IndexedDBAudioStorage.getAllChunks();
                // console.log("All chunks retrieved successfully", chunks);
                const audioBlob = new Blob(chunks, { type: "audio/webm" });
                this.audioUrl = URL.createObjectURL(audioBlob);
            } catch (error) {
                console.error("Error finalizing recording:", error);
            }
        },
        async downloadAudio(tonext = '') {
            await this.finalizeRecording();
            if (this.audioUrl) {
                const a = document.createElement("a");
                a.href = this.audioUrl;
                a.download = "audio_" + Date.now() + ".webm";
                a.click();
                this.outlanjie = false;
                this.recordingState = 2; // 本地保存也算作保存成功
            }

            if (tonext !== '') {
                console.log(tonext);

            }
        },
        openrecordtool() {
            if (this.elapsedTime < 60) {
                this.tipredshow = true;
                return;
            }
            const isfirst_g = this.$cookies.get("isfirst_g");
            if (!isfirst_g) {
                this.$cookies.set("isfirst_g", true, { expires: 30 });
                this.$refs.fristSave.openModal();
            }
            this.recordtool = 'language'
            this.generateNotes();
        },
        chooeselanguage(language, Languagename) {
            this.chooeseLanguage = language
            this.chooeseLanguageName = Languagename
            this.$emit("chooeselanguagein", this.appkey.code, this.appkey.name);
            this.$emit("chooeselanguage", language, Languagename);
        },
        async generateNotes() {
            const chunks = await IndexedDBAudioStorage.getAllChunks();
            await IndexedDBAudioStorage.clearDB();
            if (!chunks) {
                console.error("No recording to upload");
                return;
            }
            this.stopRecording();

            this.isloading = true;
            const audioBlob = new Blob(chunks);
            const name = "audio_" + Date.now() + ".mp3";
            // console.log('recode-time', this.elapsedTime);
            this.$emit("upload_audio", 'radio', audioBlob, name, this.elapsedTime, this.textobjs, this.noteid);
        },
        async save_audio_notes() {
            try {

                if (this.partobjs.length <= 0) {
                    this.$message.error('No data can be saved, please save the audio locally'); return;
                }

                // 1. 获取录音数据
                const chunks = await IndexedDBAudioStorage.getAllChunks();
                if (!chunks || chunks.length === 0) {
                    this.$message.error('No audio data available to save');
                    return;
                }

                // 2. 打开保存对话框
                this.outlanjie = false;
                this.uploadProgress = 1;
                this.$refs.pRecordsave.openModal();

                // 等待所有笔记任务完成
                await this.waitForAllNotesComplete();

                // 3. 准备音频文件
                const audioBlob = new Blob(chunks, { type: 'audio/webm' });
                const timestamp = Date.now();
                const fileName = `audio_${timestamp}.webm`;
                const file = new File([audioBlob], fileName, { type: 'audio/webm' });

                // 4. 上传到R2存储
                try {
                    const uploadResult = await this.uploadToR2(file);
                    if (!uploadResult.success) {
                        throw new Error('Upload failed');
                    }

                    // 5. 上传成功后的处理
                    this.$message.success('Audio saved successfully');
                    this.uploadProgress = 100;
                    this.$emit('upload_audio', 'record', fileName, uploadResult.fileKey, this.elapsedTime, this.textobjs, this.noteid);
                    this.recordingState = 2; // 设置为保存成功状态

                } catch (uploadError) {
                    console.error('Upload error:', uploadError);
                    // 显示失败弹窗
                    this.$refs.saveFailedPopup.openModal();
                }

            } catch (error) {
                console.error('Error in save_audio_notes:', error);
                this.$message.error('An error occurred while saving the audio');
            } finally {
                // 清理和重置状态
                this.uploadProgress = 100;
            }
        },

        setupPlaybackAnalyser() {
            if (!this.playaudioContext || this.playaudioContext.state === "closed") {
                this.playaudioContext = new (window.AudioContext ||
                    window.webkitAudioContext)();
                this.audioSource = null;
                this.playbackAnalyser = null;
            }
            if (this.playaudioContext.state === "suspended") {
                this.playaudioContext.resume();
            }
            if (!this.playbackAnalyser) {
                this.playbackAnalyser = this.playaudioContext.createAnalyser();
                this.playbackAnalyser.fftSize = 2048;
            }
            if (!this.audioSource) {
                this.audioSource = this.playaudioContext.createMediaElementSource(
                    this.$refs.audioPlayer
                );
                this.audioSource.connect(this.playbackAnalyser);
                this.playbackAnalyser.connect(this.playaudioContext.destination);
            }
            this.playbackDataArray = new Uint8Array(
                this.playbackAnalyser.frequencyBinCount
            );
        },
        drawPlaybackWaveform() {
            if (!this.playbackAnalyser) return;
            this.playbackAnalyser.getByteTimeDomainData(this.playbackDataArray);
            const canvas = this.$refs.waveformCanvas_R;
            const canvasCtx = canvas.getContext("2d");
            const width = canvas.width;
            const height = canvas.height;
            canvasCtx.fillStyle = "#15171f";
            canvasCtx.fillRect(0, 0, width, height);
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = "#ff4444";
            canvasCtx.beginPath();
            const sliceWidth = (width * 1.0) / this.playbackDataArray.length;
            let x = 0;
            for (let i = 0; i < this.playbackDataArray.length; i++) {
                const v = this.playbackDataArray[i] / 128.0;
                const y = (v * height) / 2;
                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }
                x += sliceWidth;
            }
            canvasCtx.lineTo(width, height / 2);
            canvasCtx.stroke();
            if (this.isPlaying) {
                this.playbackAnimationFrame = requestAnimationFrame(
                    this.drawPlaybackWaveform
                );
            }
        },
        drawWaveform() {
            if (!this.isRecording) {
                return;
            }
            this.analyser.getByteTimeDomainData(this.dataArray);
            this.canvasContext.fillStyle = "#15171f";
            this.canvasContext.fillRect(
                0,
                0,
                this.$refs.waveformCanvas.width,
                this.$refs.waveformCanvas.height
            );
            this.canvasContext.lineWidth = 2;
            this.canvasContext.strokeStyle = "#ff4444";
            this.canvasContext.beginPath();
            const sliceWidth =
                (this.$refs.waveformCanvas.width * 1.0) /
                this.analyser.frequencyBinCount;
            let x = 0;
            for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
                const v = this.dataArray[i] / 128.0;
                const y = (v * this.$refs.waveformCanvas.height) / 2;
                if (i === 0) {
                    this.canvasContext.moveTo(x, y);
                } else {
                    this.canvasContext.lineTo(x, y);
                }
                x += sliceWidth;
            }
            this.canvasContext.lineTo(
                this.$refs.waveformCanvas.width,
                this.$refs.waveformCanvas.height / 2
            );
            this.canvasContext.stroke();
            this.animationFrame = requestAnimationFrame(this.drawWaveform);
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const element = this.$refs.alltextbox;
                element.scrollTop = element.scrollHeight;
            });
        },
        saveaudio() {

            this.outlanjie = false;
            this.$cookies.set('ischilddeep', '')
            // if (this.radiostatus == 'startradio') {
            //     this.stopRecording();
            // }
            if (this.radiostatus == 'stopradio' || (this.radiostatus == 'startradio' && !this.isRecording)) {
                this.downloadAudio();
            }
            this.$refs.leaveSave.closeModal();
        },
        saveData() {
            // 执行保存数据的逻辑
            if (this.radiostatus == 'startradio') {
                this.stopRecording();
                this.openrecordtool();
            }
            this.outlanjie = false;
            this.$cookies.set('ischilddeep', '')
            this.$refs.leaveSave.closeModal();
        },
        cancelSave(tonext) {
            console.log('cansel save', tonext);
            // this.$cookies.set('ischilddeep', '')
            this.outlanjie = false;
            this.$refs.leaveSave.closeModal();
        },
        openvipchaoxian() {
            this.$emit('openvipchaoxian')
        },
        // 处理重试上传
        async handleRetryUpload() {
            await this.save_audio_notes();
        },

        // 处理关闭弹窗
        handleCloseFailedPopup() {
            // 可以在这里添加关闭后的其他操作
            // this.downloadAudio(); // 默认保存到本地
            this.$refs.pRecordsave.closeModal();

        },
        // 初始化定时器
        initTaskTimers() {
            // 每3秒处理一次队列
            this.queueTimer = setInterval(this.processNoteQueue, 3000);
            // 每30秒重试失败任务
            this.retryTimer = setInterval(this.retryFailedTasks, 10000);
        },

        // 添加任务到队列
        addNoteTask(partObj, index) {
            const task = {
                id: this.generateUUID8(),
                partObj: JSON.parse(JSON.stringify(partObj)), // 深拷贝
                index: index, // 保存在partobjs中的位置
                retryCount: 0,
                timestamp: Date.now()
            };
            this.noteTaskQueue.push(task);
        },

        // 处理任务队列
        async processNoteQueue() {
            if (this.isProcessingQueue || this.noteTaskQueue.length === 0) return;

            this.isProcessingQueue = true;
            const task = this.noteTaskQueue[0];

            try {
                const result = await this.processNoteTask(task);
                if (result) {
                    // 更新partobjs中对应位置的笔记
                    this.updatePartObjsNote(task.index, task.partObj.partobj_id, result);
                }
                this.noteTaskQueue.shift();
            } catch (error) {
                console.error('Failed to process note task:', error);
                const failedTask = this.noteTaskQueue.shift();
                if (failedTask.retryCount < 3) { // 最多重试3次
                    failedTask.retryCount++;
                    this.failedTasks.push(failedTask);
                }
            } finally {
                this.isProcessingQueue = false;
            }
        },

        // 处理单个笔记任务
        async processNoteTask(task) {
            const subid = this.$cookies.get("subid", "");
            let notelanguage = this.languagesItem.name;
            if (this.languagesItem?.code == 'ZH') notelanguage = 'Chinese';
            if (this.languagesItem?.code == 'ZH(T)') notelanguage = 'Traditional Chinese (Hong Kong)';

            const res = await this.$axios.post('/api/create_partnote/', {
                part_obj: JSON.stringify(task.partObj),
                text_objs: JSON.stringify(this.textobjs),
                noteid: this.noteid,
                subid: subid,
                elapsedTime: this.elapsedTime,
                note_name: this.recordtitle,
                languagein: this.appkey.code,
                language: notelanguage,
            });

            if (res.data.code === 200) {
                this.noteid = res.data.noteid;
                return this.pollNoteResult(res.data.askid);
            }
            return null;
        },

        // 轮询笔记生成结果
        async pollNoteResult(askId) {
            return new Promise((resolve, reject) => {
                let attempts = 0;
                const maxAttempts = 20; // 最多尝试20次

                const checkResult = async () => {
                    try {
                        const res = await this.$axios.post('/api/get_partnote/', { askid: askId });
                        if (res.data.code === 200) {
                            resolve(res.data.answer);
                        } else if (res.data.code === 201) {
                            resolve(null);
                        } else if (attempts < maxAttempts) {
                            attempts++;
                            setTimeout(checkResult, 1000); // 每秒查询一次
                        } else {
                            reject(new Error('Max polling attempts reached'));
                        }
                    } catch (error) {
                        reject(error);
                    }
                };

                checkResult();
            });
        },

        // 更新partobjs中的笔记
        updatePartObjsNote(index, partObjId, noteContent) {
            // 先尝试使用保存的索引
            if (this.partobjs[index]?.partobj_id === partObjId) {
                this.$set(this.partobjs[index], 'notes', this.md.render(noteContent));
                return;
            }

            // 如果索引位置不匹配，查找正确的位置
            const correctIndex = this.partobjs.findIndex(p => p.partobj_id === partObjId);
            if (correctIndex !== -1) {
                this.$set(this.partobjs[correctIndex], 'notes', this.md.render(noteContent));
            }
        },

        // 重试失败任务
        async retryFailedTasks() {
            if (this.failedTasks.length === 0) return;

            // 按原始索引排序，确保任务按正确顺序重试
            const tasksToRetry = this.failedTasks
                .filter(task => Date.now() - task.timestamp > 10000)
                .sort((a, b) => a.index - b.index);

            for (const task of tasksToRetry) {
                task.timestamp = Date.now();
                this.noteTaskQueue.push(task);
                this.failedTasks = this.failedTasks.filter(t => t.id !== task.id);
            }
        },
        // 修改stopRecording方法
        async stopRecording() {
            this.radiostatus = "stopradio";

            // 处理最后一个未完成的片段
            if (this.partobj.text) {
                this.partobj.partobj_id = this.partobj.partobj_id || this.generateUUID8();
                const currentIndex = this.partobjs.length;
                this.partobjs.push(this.partobj);
                this.addNoteTask(this.partobj, currentIndex);
            }

            this.pauseTime = 0;
            this.isRecording = false;
            this.isPaused = false;
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
                this.mediaRecorder.onstop = () => {
                    // 关闭麦克风
                    if (this.stream) {
                        this.stream.getTracks().forEach(track => track.stop());
                    }
                };
            }
            this.mediaRecorder = null;
            this.stream = null;

            this.streamobj = { stream_time: "", stream_text: "", stream_trans: "" }
            if (this.timer) {
                clearInterval(this.timer);
            }
            clearInterval(this.keepAliveInterval);
            cancelAnimationFrame(this.animationFrame);
            if (this.audioContext && this.audioContext.state !== "closed") {
                this.audioContext
                    .close()
                    .catch((error) =>
                        console.error("Error closing audio context:", error)
                    );
            }
            if (this.analyser) {
                this.analyser.disconnect();
                this.analyser = null;
            }
            if (this.websocket) {
                this.websocket.close();
            }
            this.audioContext = null;

            await this.save_audio_notes();
        },
        // 添加等待所有笔记完成的方法
        async waitForAllNotesComplete() {
            return new Promise((resolve) => {
                const checkQueue = setInterval(() => {
                    if (this.noteTaskQueue.length === 0 && this.failedTasks.length === 0 && !this.isProcessingQueue) {
                        clearInterval(checkQueue);
                        resolve();
                    }
                }, 1000);
            });
        },
        initResizer() {
            const resizer = this.$refs.resizer;
            const leftPane = this.$refs.recordlecture;
            const rightPane = this.$refs.recordtotext;
            const container = this.$el.querySelector('.recode_notesbox');

            if (!resizer || !leftPane || !rightPane || !container) {
                return;
            }

            // // 恢复保存的宽度
            // const savedWidth = localStorage.getItem('recordLectureWidth');
            // if (savedWidth) {
            //     leftPane.style.width = savedWidth;
            //     resizer.style.left = `calc(${savedWidth} + 20px)`;
            // } else {
            //     // 设置初始位置
            //     resizer.style.left = 'calc(50% + 20px)';
            // }

            let x = 0;
            let leftWidth = 0;

            const mouseDownHandler = (e) => {
                x = e.clientX;
                leftWidth = leftPane.getBoundingClientRect().width;
                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
                resizer.style.transition = 'none';
            };

            const mouseMoveHandler = (e) => {
                const dx = e.clientX - x;
                const containerWidth = container.getBoundingClientRect().width;
                const minWidth = 200; // 最小宽度 200px
                const maxWidth = containerWidth - 200; // 右侧也预留最小宽度

                let newLeftWidth = leftWidth + dx;
                newLeftWidth = Math.max(minWidth, Math.min(maxWidth, newLeftWidth));

                const newLeftWidthPercent = (newLeftWidth / containerWidth) * 100;
                leftPane.style.width = `${newLeftWidthPercent}%`;

                // 更新拖动条位置
                resizer.style.left = `calc(${newLeftWidthPercent}% + 20px)`;
            };

            const mouseUpHandler = () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
                resizer.style.transition = 'left 0.2s';
                localStorage.setItem('recordLectureWidth', leftPane.style.width);
            };

            resizer.addEventListener('mousedown', mouseDownHandler);

            // 添加窗口大小改变时的处理
            window.addEventListener('resize', () => {
                const containerWidth = container.getBoundingClientRect().width;
                const currentWidth = leftPane.getBoundingClientRect().width;
                const widthPercent = (currentWidth / containerWidth) * 100;
                leftPane.style.width = `${widthPercent}%`;
                resizer.style.left = `calc(${widthPercent}% + 20px)`;
            });
        },
        async handleNetworkChange(event) {
            this.isOnline = navigator.onLine;
            const timestamp = new Date().toISOString();

            if (this.isOnline) {
                console.log('网络已连接:', {
                    timestamp,
                    type: event.type,
                    connection: navigator.connection ? {
                        type: navigator.connection.effectiveType,
                        downlink: navigator.connection.downlink
                    } : 'Not available'
                });
                this.resumeRecording();
            } else {
                console.log('网络已断开:', {
                    timestamp,
                    type: event.type
                });
                this.pauseRecording();
                // 断网时关闭WebSocket连接
                if (this.websocket) {
                    this.disconnectWebSocket();
                }
            }
        },
        // WebSocket重连机制
        async reconnectWebSocket() {
            // 清除之前的重连任务
            if (this.reconnectTimer) {
                clearInterval(this.reconnectTimer);
                this.reconnectTimer = null;
            }
            if (this.checkConnectionTimer) {
                clearInterval(this.checkConnectionTimer);
                this.checkConnectionTimer = null;
            }

            let retryCount = 0;
            const maxRetries = 30;
            const retryInterval = 2000;
            const connectionTimeout = 10000; // 增加到10秒
            const checkInterval = 2000; // 增加检查间隔到2秒

            const connectWebSocketWithRetry = async () => {
                try {
                    await new Promise((resolve, reject) => {
                        // 检查网络状态和质量
                        if (!navigator.onLine) {
                            reject(new Error('No network connection'));
                            return;
                        }

                        // 获取网络信息
                        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
                        if (connection) {
                            console.log('网络状态:', {
                                type: connection.effectiveType,
                                downlink: connection.downlink,
                                rtt: connection.rtt
                            });
                        }

                        this.connectWebSocket();
                        let checkCount = 0;
                        
                        // 连接成功检查
                        this.checkConnectionTimer = setInterval(() => {
                            checkCount++;
                            if (this.websocket) {
                                // 连接成功
                                if (this.websocket.readyState === WebSocket.OPEN) {
                                    clearInterval(this.checkConnectionTimer);
                                    this.checkConnectionTimer = null;
                                    resolve();
                                } 
                                // 连接失败
                                else if (this.websocket.readyState === WebSocket.CLOSED || 
                                        this.websocket.readyState === WebSocket.CLOSING) {
                                    clearInterval(this.checkConnectionTimer);
                                    this.checkConnectionTimer = null;
                                    reject(new Error(`WebSocket state: ${this.websocket.readyState}`));
                                }
                                // 仍在连接中，但已经检查多次
                                else if (checkCount * checkInterval >= connectionTimeout) {
                                    clearInterval(this.checkConnectionTimer);
                                    this.checkConnectionTimer = null;
                                    reject(new Error(`Connection timeout after ${connectionTimeout}ms`));
                                }
                            }
                        }, checkInterval);

                        // 设置连接超时
                        setTimeout(() => {
                            if (this.checkConnectionTimer) {
                                clearInterval(this.checkConnectionTimer);
                                this.checkConnectionTimer = null;
                                reject(new Error(`Connection timeout after ${connectionTimeout}ms`));
                            }
                        }, connectionTimeout);
                    });
                    
                    console.log('WebSocket连接成功');
                    return true;

                } catch (error) {
                    // 获取当前网络状态信息
                    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
                    const networkInfo = connection ? {
                        type: connection.effectiveType,
                        downlink: connection.downlink,
                        rtt: connection.rtt
                    } : 'Not available';

                    console.error('WebSocket连接失败:', {
                        attempt: retryCount + 1,
                        maxRetries: maxRetries,
                        error: error.message,
                        timestamp: new Date().toISOString(),
                        websocketState: this.websocket?.readyState,
                        networkOnline: navigator.onLine,
                        networkInfo: networkInfo
                    });

                    retryCount++;
                    
                    if (retryCount < maxRetries) {
                        // 根据网络质量动态调整重试间隔
                        const currentRetryInterval = connection && connection.rtt > 100 
                            ? retryInterval * 1.5  // 网络延迟高时增加间隔
                            : retryInterval;

                        await new Promise(resolve => {
                            this.reconnectTimer = setTimeout(resolve, currentRetryInterval);
                        });
                        return connectWebSocketWithRetry();
                    }

                    this.$message.error('无法建立连接，请检查网络后重试');
                    return false;
                }
            };

            return connectWebSocketWithRetry();
        },
        // 断开WebSocket连接
        disconnectWebSocket() {
            // 清除所有定时器
            if (this.reconnectTimer) {
                clearInterval(this.reconnectTimer);
                this.reconnectTimer = null;
            }
            if (this.checkConnectionTimer) {
                clearInterval(this.checkConnectionTimer);
                this.checkConnectionTimer = null;
            }

            if (this.websocket) {
                try {
                    this.websocket.close(1000, '用户主动关闭连接');
                } catch (error) {
                    console.error('关闭WebSocket时发生错误:', error);
                }
                clearInterval(this.keepAliveInterval);
                this.websocket = null;
                this.isConnected = false;
            }
        },
    },
    beforeDestroy() {
        this.$cookies.set('ischilddeep', '')
        cancelAnimationFrame(this.animationFrame);
        cancelAnimationFrame(this.playbackAnimationFrame);
        clearInterval(this.keepAliveInterval);
        IndexedDBAudioStorage.clearDB()
        if (this.audioSource) {
            this.audioSource.disconnect();
        }
        if (this.playbackAnalyser) {
            this.playbackAnalyser.disconnect();
        }
        this.clearRecording();
        if (this.queueTimer) clearInterval(this.queueTimer);
        if (this.retryTimer) clearInterval(this.retryTimer);
        // 移除网络状态监听
        window.removeEventListener('online', this.handleNetworkChange);
        window.removeEventListener('offline', this.handleNetworkChange);
    },
};
</script>

<style lang="less">
@import '@/assets/styles/real_radio.less';

.realtimeaudio {
    .recode_notesbox {
        display: flex;
        gap: 10px; // 添加固定间距
        padding: 10px;
        height: 100%;

        .recordlecture {
            flex: none;
            width: 490px; // 默认宽度
            min-width: 490px; // 最小宽度
        }

        .recordtotext {
            flex: 1;
        }

        .resizer {
            width: 0px; // 增加宽度使其更容易拖动

            border: 1px solid rgba(255, 255, 255, 0.2);
            cursor: col-resize;
            user-select: none;
            z-index: 1;

            &:hover {
                border: 1px solid rgba(255, 255, 255, 0.6);
            }
        }
    }
}
</style>
