<template>
    <el-dialog :visible.sync="visible" width="580px" custom-class="save-failed-dialog" :show-close="false"
        :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="save-failed-content">
            <div class="icon-wrapper">
                <i class="el-icon-warning"></i>
            </div>
            <h2>Save Failed</h2>
            <p>The audio recording failed to save. Please try again or save locally.</p>
            <div class="button-group">
                <el-button type="primary" @click="handleRetry">Retry</el-button>
                <el-button @click="handleClose">Close</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'PopupSaveFailed',
    data() {
        return {
            visible: false
        }
    },
    methods: {
        openModal() {
            this.visible = true
        },
        closeModal() {
            this.visible = false
        },
        handleRetry() {
            this.$emit('retry')
            this.closeModal()
        },
        handleClose() {
            this.$emit('close')
            this.closeModal()
        }
    }
}
</script>

<style lang="less">

.save-failed-dialog {
    background: #1E1F2B !important;
    border-radius: 12px;    
    margin-top: 30vh !important;
    :deep(.el-dialog__body) {
        padding: 30px 20px;
    }
}

.save-failed-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    .icon-wrapper {
        width: 48px;
        height: 48px;
        background: rgba(255, 68, 68, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        i {
            color: #ff4444;
            font-size: 24px;
        }
    }

    h2 {
        font-size: 22px;
        margin: 0 0 10px;
    }
    p{
        font-size: 16px;
        margin-bottom: 20px;
    }

    .button-group {
        display: flex;
        gap: 12px;
        margin: 20px 0;
        .el-button {
            min-width: 100px;
            border-radius: 30px;
            padding: 10px 20px;
            font-size: 16px;
        }
    }
}
</style> 